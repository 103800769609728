import React from "react";
import "./css/Header.css";
import Man from "../img/khurshed-3.jpg";
import Epf from "../img/epf.jpeg";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import ScrollToPlugin from "gsap/ScrollToPlugin";

const Header = () => {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

  useGSAP(() => {
    const tl = gsap.timeline();

    tl.to(".t1", {
      opacity: 1,
      y: 0,
      ease: "expo.out",
      duration: 0.5,
      stagger: 0.15,
    })
      .fromTo(
        ".t2",
        {
          "--width": "100%",
        },
        {
          "--width": "0%",
          duration: 0.5,
        },
        "-=0.4"
      )
      .fromTo(
        ".t3",
        {
          x: -20,
        },
        {
          "--width": "10%",
          x: 30,
          duration: 0.3,
          ease: "expo.out",
        },
        "-=0.4"
      );
    gsap.fromTo(
      ".t3",
      { "--width": "10%" },
      {
        "--width": "100%",
        scrollTrigger: {
          trigger: "header",
          start: "50px top",
          endTrigger: ".t3",
          end: "bottom 10%",
          scrub: 0.5,
          once: true,
        },
      }
    );

    gsap
      .timeline({
        scrollTrigger: {
          trigger: "nav",
          start: "10px top",
          scrub: true,
        },
      })
      .to(
        ".t4.fa",
        {
          fontSize: 20,
        },
        0
      )
      .to(
        ".t4.fb",
        {
          fontSize: 35,
        },
        0
      )
      .to(
        ".nav-logo-header",
        {
          top: 10,
        },
        0
      );
  });

  return (
    <header className="container fluid center">
      <div className="header-logo">
        <span className="accent-dark font-1 -m fa">at</span>
        <div
          className="nav-logo-header"
          onClick={() => gsap.to(window, { scrollTo: "top" })}
        >
          <span className="font-1 mx-2 mt-2 t4 fa">SAYYED KHURSHED ALAM</span>
          <h1 className="font-2 mx-2 mnt-1 t4 fb">CONSULTANCY</h1>
        </div>
      </div>
      <div className="logo">
        <a href="/">
          <i className="bx bxl-instagram"></i>
        </a>
        <a href="/">
          <i className="bx bxl-facebook"></i>
        </a>
        <a href="/">
          <i className="bx bxl-twitter"></i>
        </a>
      </div>

      {window.innerWidth <= 750 ? (
        <div id="header-text" style={{ marginTop: 170 }}>
          <div className="container fluid hidden">
            <span className="t1">We </span>
            
            <span className="t1">handle </span>
          </div>
          <div className="container fluid hidden">
            <span className="t1">Your </span>
            <span className="bold italic">EPF </span>
            <span>
              <img src={Epf} alt="" className="header-man" />
            </span>
          </div>
          <div>
            <span className="bold italic">ESIC, </span>
            <div className="t2">
              <span>so </span>
              <span>you </span>
            </div>
          </div>
          <div className="container fluid hidden t2">
            <span>focus </span>
            <span>on</span>
          </div>
          <div>
            <span>
              <i className="bx bx-right-arrow-alt mt-2"></i>
            </span>
            <span className="t3 font-2 italic"> success. </span>
          </div>
          <span className=" container mt-5 flex right">
            <div
              style={{
                fontSize: "clamp(24px, 2vw, 28px",
                maxWidth: 300,
                color: "var(--dark)",
                textAlign: "left",
              }}
            >
              we help by doing your epf and esic for your employees
            </div>
          </span>
        </div>
      ) : (
        <div id="header-text">
          <div className="container fluid hidden">
            <span className="t1">We </span>
            <span
              style={{
                overflow: "hidden",
                borderRadius: "50%",
                height: 100,
                width: 100,
                position: "relative",
              }}
            >
              <img src={Man} alt="" className="header-man a" />
            </span>
            <span className="t1">handle </span>
            <span className="t1">Your </span>
            <span className="bold italic">EPF </span>
            <span>
              <img src={Epf} alt="" className="header-man" />
            </span>
          </div>
          <div>
            <span className="bold italic">ESIC, </span>
            <div className="t2">
              <span>so </span>
              <span>you </span>
              <span>focus </span>
            </div>
          </div>
          <div>
            <span>on</span>
            <span>
              <i className="bx bx-right-arrow-alt mt-2"></i>
            </span>
            <span className="t3 font-2 italic"> success. </span>
          </div>
          <span className=" container mt-5 flex right">
            <div
              style={{
                fontSize: "clamp(24px, 2vw, 28px",
                maxWidth: 300,
                color: "var(--dark)",
                textAlign: "left",
              }}
            >
              we help by doing your epf and esic for your employees
            </div>
          </span>
        </div>
      )}
      
    </header>
  );
};

export default Header;
